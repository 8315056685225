import axios from "axios";
import { Message } from "element-ui";
import { Toast } from "mint-ui";

let Axios = axios.create({});

Axios.defaults.baseURL = 'https://api.dolphin-ai.jp';

Axios.defaults.headers.post["Content-Type"] = "application/json";

window._axiosPromiseArr = []
// 请求拦截器
Axios.interceptors.request.use(
    config => {
        config.cancelToken = new axios.CancelToken(cancel => {
            window._axiosPromiseArr.push({ cancel })
        })
        return config;
    },
    error => {
        return Promise.error(error);
    }
);

// 响应拦截器
Axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response.data)
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (window.innerWidth <= 768) {
            if (error.response) {
                Toast(error.response.data);
                return Promise.reject(error.response);
            } else {
                Toast('Request failed, please try again later.');
                return Promise.reject(error);
            }
        } else {
            if (error.response) {
                Message({
                    message: error.response.data,
                    type: 'error'
                });
                return Promise.reject(error.response);
            } else {
                Message({
                    message: 'Request failed, please try again later.',
                    type: 'error'
                });
                return Promise.reject(error);
            }
        }
    }
);

export default Axios;
