import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css'
import './assets/css/common.css'
import '@/assets/js/rem'
import { Toast } from "mint-ui";
Vue.prototype.$Toast = Toast

import Axios from "./Axios"
Vue.prototype.$Axios = Axios

import SoeEngine from "@/assets/js/engine/soeEngine.min"
Vue.prototype.$SoeEngine = SoeEngine

Vue.prototype.$globalParam = {
  preService: false, // 预发布环境：true  正式环境：false
  log: false,  // 是否开启log输出

  // 预发布环境
  // appId: '85f68b7b-ffd5-45d6-84de-323139a7f251',
  // appSecret : '61bc9abbd2e8fe37',

  // 正式环境
  appId: '1f47fcff-5557-47b3-8727-c6c7b3625278',
  appSecret : '66e7fe2eb139a87a',
}

Vue.prototype.$lingomate = ""
Vue.prototype.$dolphinsoe = "https://soe.dolphin-ai.jp"
Vue.prototype.$dolphinvoice = "https://voice.dolphin-ai.jp"
// Vue.prototype.$dolphinsoe = "https://xz502.huiyan-tech.com:8200/dolphinSoe/dist/#/"

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
