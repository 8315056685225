import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

let routes;

if(window.innerWidth <= 768) {
  //移动端
 routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home_Mobile.vue'),
      children: [
        {
          path: '',
          name: 'Index',
          component: () => import('../components/Index_Mobile.vue')
        },
        {
          path: '/engine',
          name: 'Engine',
          component: () => import('../components/AIEngine_Mobile.vue')
        },
        {
          path: '/soe',
          name: 'SoeAI',
          component: () => import('../components/SoeAI_Mobile.vue')
        },
        {
          path: '/demo',
          name: 'SoeDemo',
          component: () => import('../components/SoeDemo_Mobile.vue'),
        },
        {
          path: '/demo-phoneme',
          name: 'SoeDemo_phoneme',
          component: () => import('../components/demo/SoeDemo_Phoneme_Mobile.vue'),
        },
        {
          path: '/demo-word',
          name: 'SoeDemo_word',
          component: () => import('../components/demo/SoeDemo_Word_Mobile.vue'),
        },
        {
          path: '/demo-sentence',
          name: 'SoeDemo_sentence',
          component: () => import('../components/demo/SoeDemo_Sentence_Mobile.vue'),
        },
        {
          path: '/demo-paragraph',
          name: 'SoeDemo_paragraph',
          component: () => import('../components/demo/SoeDemo_Paragraph_Mobile.vue'),
        },
        {
          path: '/demo-wordmistake',
          name: 'SoeDemo_wordmistake',
          component: () => import('../components/demo/SoeDemo_WordMistake_Mobile.vue'),
        },
        {
          path: '/demo-sentencemistake',
          name: 'SoeDemo_sentencemistake',
          component: () => import('../components/demo/SoeDemo_SentenceMistake_Mobile.vue'),
        },
        {
          path: '/demo-oralquestions',
          name: 'SoeDemo_oralquestions',
          component: () => import('../components/demo/SoeDemo_OralQuestions_Mobile.vue'),
        },
        {
          path: '/demo-story',
          name: 'SoeDemo_story',
          component: () => import('../components/demo/SoeDemo_Story_Mobile.vue'),
        },
        {
          path: '/demo-oralcomposition',
          name: 'SoeDemo_oralcomposition',
          component: () => import('../components/demo/SoeDemo_OralComposition_Mobile.vue'),
        },
        {
          path: '/demo-recite',
          name: 'SoeDemo_recite',
          component: () => import('../components/demo/SoeDemo_Recite_Mobile.vue'),
        },
        {
          path: '/demo-choise',
          name: 'SoeDemo_choise',
          component: () => import('../components/demo/SoeDemo_Choise_Mobile.vue'),
        },
        {
          path: '/demo-freechoise',
          name: 'SoeDemo_freechoise',
          component: () => import('../components/demo/SoeDemo_FreeChoise_Mobile.vue'),
        },
        {
          path: '/demo-freedom',
          name: 'SoeDemo_freedom',
          component: () => import('../components/demo/SoeDemo_Freedom_Mobile.vue'),
        },
        {
          path: '/company',
          name: 'Company',
          component: () => import('../components/Company_Mobile.vue')
        },
        {
          path: '/contact',
          name: 'Contact',
          component: () => import('../components/Contact_Mobile.vue')
        },
        {
          path: '/file',
          name: 'File',
          component: () => import('../components/File_Mobile.vue')
        }
      ]
    }
  ]

}
else {
  //web端
  routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue'),
      children: [
        {
          path: '',
          name: 'Index',
          component: () => import('../components/Index.vue')
        },
        {
          path: '/engine',
          name: 'Engine',
          component: () => import('../components/AIEngine.vue')
        },
        {
          path: '/soe',
          name: 'SoeAI',
          component: () => import('../components/SoeAI.vue')
        },
        {
          path: '/demo',
          name: 'SoeDemo',
          component: () => import('../components/SoeDemo.vue'),
        },
        {
          path: '/demo-phoneme',
          name: 'SoeDemo_phoneme',
          component: () => import('../components/demo/SoeDemo_Phoneme.vue'),
        },
        {
          path: '/demo-word',
          name: 'SoeDemo_word',
          component: () => import('../components/demo/SoeDemo_Word.vue'),
        },
        {
          path: '/demo-sentence',
          name: 'SoeDemo_sentence',
          component: () => import('../components/demo/SoeDemo_Sentence.vue'),
        },
        {
          path: '/demo-paragraph',
          name: 'SoeDemo_paragraph',
          component: () => import('../components/demo/SoeDemo_Paragraph.vue'),
        },
        {
          path: '/demo-wordmistake',
          name: 'SoeDemo_wordmistake',
          component: () => import('../components/demo/SoeDemo_WordMistake.vue'),
        },
        {
          path: '/demo-sentencemistake',
          name: 'SoeDemo_sentencemistake',
          component: () => import('../components/demo/SoeDemo_SentenceMistake.vue'),
        },
        {
          path: '/demo-oralquestions',
          name: 'SoeDemo_oralquestions',
          component: () => import('../components/demo/SoeDemo_OralQuestions.vue'),
        },
        {
          path: '/demo-story',
          name: 'SoeDemo_story',
          component: () => import('../components/demo/SoeDemo_Story.vue'),
        },
        {
          path: '/demo-oralcomposition',
          name: 'SoeDemo_oralcomposition',
          component: () => import('../components/demo/SoeDemo_OralComposition.vue'),
        },
        {
          path: '/demo-recite',
          name: 'SoeDemo_recite',
          component: () => import('../components/demo/SoeDemo_Recite.vue'),
        },
        {
          path: '/demo-choise',
          name: 'SoeDemo_choise',
          component: () => import('../components/demo/SoeDemo_Choise.vue'),
        },
        {
          path: '/demo-freechoise',
          name: 'SoeDemo_freechoise',
          component: () => import('../components/demo/SoeDemo_FreeChoise.vue'),
        },
        {
          path: '/demo-freedom',
          name: 'SoeDemo_freedom',
          component: () => import('../components/demo/SoeDemo_Freedom.vue'),
        },
        {
          path: '/company',
          name: 'Company',
          component: () => import('../components/Company.vue')
        },
        {
          path: '/contact',
          name: 'Contact',
          component: () => import('../components/Contact.vue')
        },
        {
          path: '/file',
          name: 'File',
          component: () => import('../components/File.vue')
        }
      ]
    }
  ]

}

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.name !== 'Company') {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})

export default router
